<template>
<v-container class="container-max-width">
  <v-row class="page-wrapper">
    <v-col offset="3" cols="7">
      <v-row>
        <v-col cols="12">
          <p class="text-h3 text-no-wrap primary--text">Try Syncio free for 14 days</p>
        </v-col>
      </v-row>
      <v-row class="subheading">
        <v-col cols="12">
          <p class="text-h6 text-no-wrap text-uppercase">Begin by selecting your store's platform</p>
        </v-col>
      </v-row>
      <v-row class="logo-wrapper">
        <v-col cols="6">
          <ShopifyBigButton @click.native="takeToShopifySyncioApp"/>
        </v-col>
        <v-col cols="6">
          <WooCommerceBigButton @click.native="goToCreateAccount"/>
        </v-col>
      </v-row>
      <v-row class="mt-16">
        <v-col>

        </v-col><v-spacer></v-spacer>
        <v-col>
          <p class="text-body-1 text-no-wrap">Already with Syncio? <router-link to="/login">Log in</router-link> instead.</p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import ShopifyBigButton from "@/views/registration/components/ShopifyBigButton";
import WooCommerceBigButton from "@/views/registration/components/WooCommerceBigButton";

export default {
  name: "SignUpPage",
  components: { WooCommerceBigButton, ShopifyBigButton },
  methods: {
    goToCreateAccount(){
      this.$router.push({name: 'registration.woocommerce.create-account'})
    },
    takeToShopifySyncioApp(){
      window.location.href = 'https://apps.shopify.com/syncio';
    }
  }
}
</script>

<style scoped>
.container-max-width{
  max-width: 1200px;
  min-width: 768px !important;
}
.page-wrapper{
  margin-top: 3%;
}
.subheading{
  margin-top: 4%;
}
.logo-wrapper{
  margin-top: 2%;
}
</style>