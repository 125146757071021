<template>
  <v-btn class="woocommerce-big-button white rounded-lg" height="314px" width="314px">
  <span>
    <v-img src="~@/assets/images/woo_logo.png" contain max-width="170px" max-height="195.7px"></v-img>
    <p class="text-body-1 mt-6 text-capitalize">WooCommerce</p>
  </span>
  </v-btn>
</template>

<script>
export default {
  name: "WooCommerceBigButton"
}
</script>

<style scoped>
.woocommerce-big-button{

}
</style>