<template>
<v-btn class="shopify-big-button white rounded-lg" height="314px" width="314px" block>
  <span>
    <v-img src="~@/assets/images/shopify_logo.png" contain max-width="170px" max-height="195.7px"></v-img>
    <p class="text-body-1 mt-6 text-capitalize">Shopify</p>
  </span>
</v-btn>
</template>

<script>
export default {
  name: "ShopifyBigButton"
}
</script>

<style scoped>
.shopify-big-button{

}
</style>